import { PublicGuard } from './shared/guards/public.guard';
import { ReservedGuard } from './shared/guards/reserved.guard';
import { VIPGuard } from './shared/guards/vip.guard';
import { SecurityGuard } from './shared/guards/security.guard';
import { CashierGuard } from './shared/guards/cashier.guard';
import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
// import { AuthGuard } from './shared/guards/auth.guard';
import { AuthGuard } from '@auth0/auth0-angular';
import { AccountantGuard } from './shared/guards/accountant.guard';
export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'Home',
    pathMatch: 'full',
    canDeactivate: [CashierGuard],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CashierGuard],
    children: [
      {
        path: 'Home',
        canDeactivate: [AccountantGuard],
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'All Traffic Overview', breadcrumb: 'All Traffic Overview' }
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'Profile', breadcrumb: 'PROFILE' }
      },
      {
        path: 'analytics',
        loadChildren: () => import('./views/crud-tables/crud-tables.module').then(m => m.CrudTablesModule),
        data: { title: 'Analytics', breadcrumb: 'Analytics' }
      },
      {
        path: 'availability',
        loadChildren: () => import('./views/availability/availability.module').then(m => m.AvailabilityModule),
        data: { title: 'Availability', breadcrumb: 'Availability' }
      },
      {
        path: 'camera-requests',
        loadChildren: () => import('./views/camerrequest/camerrequest.module').then(m => m.CamerrequestModule),
        data: { title: 'Camera requests', breadcrumb: 'Camera requests' },
        canDeactivate: [AccountantGuard],

      },
      {
        path: 'Sites',
        loadChildren: () => import('./views/sites/sites.module').then(m => m.SiteModule),
        data: { title: 'Sites', breadcrumb: 'Sites' },
        canDeactivate: [AccountantGuard],

      },
      {
        path: 'Lists',
        loadChildren: () => import('./views/lists/Lists.module').then(m => m.ListsModule),
        data: { title: 'Lists', breadcrumb: 'Lists' },
        canActivate: [PublicGuard],
        canDeactivate: [AccountantGuard],
      },
      {
        path: 'BlackLists',
        loadChildren: () => import('./views/blacklist/blacklist.module').then(m => m.BlacklistModule),
        data: { title: 'BlackLists', breadcrumb: 'BlackLists' },
        canActivate: [],
        canDeactivate: [AccountantGuard],

      },
      {
        path: 'Parking-fees',
        loadChildren: () => import('./views/parking-fees/parkign-fees.module').then(m => m.ParkingFeesModule),
        data: { title: 'Parking Fees', breadcrumb: 'Parking Fees' },
        canActivate: [PublicGuard],
        canDeactivate: [AccountantGuard],

      },
      {
        path: 'User-mangment',
        loadChildren: () => import('./views/user-mangment/user-mangment.module').then(m => m.UserMangmentModule),
        data: { title: 'User Managment', breadcrumb: 'User Managment' },
        canDeactivate: [AccountantGuard],

      },
      {
        path: 'VIPList',
        loadChildren: () => import('./views/VIP-list/vipLists.module').then(m => m.VIPListsModule),
        data: { title: 'VIP White List', breadcrumb: 'VIP White List' },
        canActivate: [VIPGuard],
        canDeactivate: [AccountantGuard],

      },
      {
        path: 'reserved-park',
        loadChildren: () => import('./views/reserved-park/reserved-park.module').then(m => m.ReservedParkModule),
        data: { title: 'Reserved Park', breadcrumb: 'Reserved Park' },
        canActivate: [ReservedGuard],
        canDeactivate: [AccountantGuard],

      },
      {
        path: 'validate',
        loadChildren: () => import('./views/validate/validate/validate.module').then(m => m.ValidateModule),
        data: { title: 'Validated', breadcrumb: 'Validated' },
        canActivate: [SecurityGuard],
        canDeactivate: [AccountantGuard],
      },

      {
        path: 'tickets',
        loadChildren: () => import('./views/tickets/tickets.module').then(m => m.TicketsModule),
        data: { title: 'Tickets', breadcrumb: 'Tickets' },
        canDeactivate: [AccountantGuard],
      },
      {
        path: 'System_Configuration',
        loadChildren: () => import('./views/system-configuration/system-configuration.module').then(m => m.SystemConfigurationModule),
        data: { title: 'System_Configuration', breadcrumb: 'System_Configuration' },
        canDeactivate: [AccountantGuard],
      },
      {
        path: 'access_control',
        loadChildren: () => import('./views/access-control/access-control.module').then(m => m.AccessControlModule),
        data: { title: 'Access Control', breadcrumb: 'Access Control' },
        canDeactivate: [AccountantGuard],
      },
      {
        path: 'tenant',
        loadChildren: () => import('./views/all-organization/tenant.module').then(m => m.TenantModule),
        data: { title: 'All Sites', breadcrumb: 'All Sites' },
        canDeactivate: [AccountantGuard],
      },
      {
        path: 'access_control',
        loadChildren: () => import('./views/access-control/access-control.module').then(m => m.AccessControlModule),
        data: { title: 'Access Control', breadcrumb: 'Access Control' },
        canDeactivate: [AccountantGuard],
      },
    ]
  },

  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

