import { Component, OnInit, Input } from "@angular/core";
import { ThemeService } from "../../services/theme.service";
import { LayoutService } from "../../services/layout.service";
import { LocalStoreService } from "app/shared/services/local-store.service";
import { NavigationEnd, Router } from "@angular/router";
import { AppLogoutService } from "app/shared/services/app-logout/app-logout.service";
import { ApiService } from "app/shared/services/api.service";
import { SingleTonService } from "app/shared/services/single-ton.service";
import { Store } from "@ngxs/store";
import { Site } from 'app/core'
import { SiteModel, Sites } from "app/shared/models/site.model";
import { newArray } from "@angular/compiler/src/util";
import { AuthService } from "@auth0/auth0-angular";
@Component({
  selector: "app-header-side",
  templateUrl: "./header-side.template.html",
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  @Input() login: string;
  user;
  clearable = false;
  public Themes;
  Sites = [];
  CurrentSite;
  public layoutConf: any;
  Parkdata = [];
  CurrentType = "";
  isMobile: boolean;
  isLaptop: boolean;
  isTablet: boolean;
  isScreen: boolean;
  tenantData = [];
  isOrganization = false;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    private ls: LocalStoreService,
    private route: Router,
    private logoutService: AppLogoutService,
    private api: ApiService,
    private singleTon: SingleTonService,
    private store: Store,
    private auth: AuthService

  ) {
    // //console.log(this.layout);
    this.layout.layoutConf$.subscribe((layoutConf) => {
      this.isMobile = layoutConf.isMobile;
      this.isLaptop = layoutConf.isLaptop;
      this.isTablet = layoutConf.isTablet;
      this.isScreen = layoutConf.isScreen;
    });

    this.user = this.ls.getItem('USER');
    ApiService.typeData = this.ls.getItem('typeData')
    this.layout.getParkObservable().subscribe((val) => {
      if (val.length > 0) {
        this.Parkdata = val;
      }
    });
    this.singleTon.getObservable().subscribe(data => {
      console.log(data)
      this.tenantData = []
      if (data.tenantData) {
        console.log(data.tenantData)
        this.tenantData = data.tenantData
      }
    })
    this.layout.getCurrentTypeObservable().subscribe(ele => {
      // //console.log(ele);
      this.CurrentType = ele.type
    })
    this.layout.getCurrentSiteObservable().subscribe(site => {
      // //console.log(site.site)
      this.CurrentSite = site.site;
      this.ls.setItem("SITE", this.CurrentSite);
      // //console.log(LayoutService.site)
      this.ls.setItem("PUBLICData", LayoutService.site?.registeredServices);
      this.ls.setItem("CURRENCY", LayoutService.site.currency);
      this.ls.setItem("PUBLIC", LayoutService.site.registeredServices.public);
      this.ls.setItem("GYM", LayoutService.site.registeredServices.gym);
      this.ls.setItem("RESERVED", LayoutService.site.registeredServices.reserved);
      this.ls.setItem("VIP", LayoutService.site.registeredServices.vip);
      this.ls.setItem("COMMUNITY", LayoutService.site.registeredServices.community);
      this.ls.setItem("OPENRESERVED", LayoutService.site.registeredServices.openReserved);
      let dataType = Object.keys(LayoutService.site.registeredServices);
      // //console.log(dataType);
      let Parkdata = [];
      let currentData = "";
      if (dataType && dataType?.length > 0) {
        dataType.forEach((ele) => {
          // //console.log(LayoutService.site.registeredServices[ele])
          if (LayoutService.site.registeredServices[ele]) {
            Parkdata.push(ele);
          }
        });
        Parkdata.forEach((ele) => {
          currentData = ele;
        });
        this.api.publishTypeData({ type: currentData })
        // //console.log(currentData, Parkdata);
        this.ls.setItem('typeData', currentData)
        this.ls.setItem('TypeArray', Parkdata)
        ApiService.typeData = currentData;
        this.CurrentType = ApiService.typeData
        this.layout.publishCurrentType({ type: currentData });
      }
      this.layout.publishParkData(Parkdata);
    })
  }
  ngOnInit() {
    if (!this.login || this.login !== "login") {
      this.Themes = this.themeService.Themes;
      this.layoutConf = this.layout.layoutConf;
    }

    this.CurrentSite = this.ls.getItem('SITE');
    this.Sites = this.ls.getItem('SITES');
    this.layout.getSiteObservable().subscribe(sites => {
      this.Sites = sites.site;
      this.addAllToSites();
    })
    this.CurrentType = this.ls.getItem('typeData')
    if (this.ls.getItem('TypeArray'))
      this.Parkdata = this.ls.getItem('TypeArray')
    this.addAllToSites();
    if (this.CurrentSite.id === 'ALL')
      this.isOrganization = true;
    else
      this.isOrganization = false;
  }
  ngOnChanges() {
    this.CurrentSite = this.singleTon.site;
    this.Sites = this.singleTon.AllSites;
    if (this.CurrentSite.id === 'ALL')
      this.isOrganization = true;
    else
      this.isOrganization = false;
  }
  addAllToSites() {
    let site: SiteModel = {
      id: "ALL",
      site: "ALL",
      currency: "",
      entrancesCount: 0,
      exitsCount: 0,
      lat: 0,
      lng: 0,
      color: "",
      code: "",
      entrances: [],
      exits: [],
      logoUrl: "",
      registeredServices: {
        "public": this.singleTon.organizationServices.includes('public'),
        "vip": this.singleTon.organizationServices.includes('vip'),
        "reserved": this.singleTon.organizationServices.includes('reserved'),
        "openReserved": this.singleTon.organizationServices.includes('openReserved'),
        "gym": this.singleTon.organizationServices.includes('gym'),
      }
    }
    if (site) {
      const newArray = [site, ...this.Sites.slice()];
      this.Sites = []
      this.Sites = newArray
    }
    this.Sites = this.Sites.filter((item, index, self) =>
      index === self.findIndex((t) => t.id === item.id)
    );

  }
  logout() {
    this.logoutService
      .confirm({ message: `Are you sure you want to logout?` })
      .subscribe((res) => {
        if (res) {
          this.ls.clear();
          this.api.logout();
        }
      });
  }
  changeTheme() {
    // this.themeService.changeTheme(theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === "closed") {
      return this.layout.publishLayoutChange({
        sidebarStyle: "full",
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: "closed",
    });
  }
  focusEvent($event) {
    console.log($event)
    console.log(this.singleTon.site)
    if ($event.id === 'ALL')
      this.isOrganization = true;
    else
      this.isOrganization = false;
    if (this.singleTon.site?.id === 'ALL' && this.route.url.includes('tenant') && $event?.site !== this.singleTon.site?.site)
      this.route.navigate([`Home/dashboard/${$event?.site}`])
    LayoutService.site = $event
    this.layout.publishCurrentSiteData({ site: $event });
    this.store.dispatch(new Site.SelectSite({ Site: $event }));

  }
  focusType($event) {
    this.ls.setItem('typeData', $event)
    this.layout.publishCurrentType({ type: $event })
    if (this.singleTon.site?.site !== 'ALL')
      this.route.navigate([`Home/dashboard/${this.singleTon.site?.site}`])

  }
  onError(event: Event) {
    console.log(event)
    const element = event.target as HTMLImageElement;
    element.src = 'assets/images/profile.png'; // Path to your fallback image
  }
  return() {
    if (this.route.url === '/Home') {
      this.clearable = true;
    } else {
      this.clearable = false;
    }
  }
  toggleCollapse() {
    // compact --> full
    if (this.layoutConf.sidebarStyle === "compact") {
      return this.layout.publishLayoutChange(
        {
          sidebarStyle: "full",
          sidebarCompactToggle: false,
        },
        { transitionClass: true }
      );
    }

    // * --> compact
    this.layout.publishLayoutChange(
      {
        sidebarStyle: "compact",
        sidebarCompactToggle: true,
      },
      { transitionClass: true }
    );
  }

  onSearch() {
    //   //console.log(e)
  }
}
