import { Component, OnInit, ViewChild, Input, HostListener, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ACTIONS, DEVICE_INNER_WINDOW_SIZE } from "../../enums/enums";
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ThemePalette } from '@angular/material/core';
import { SelectionModel } from '@angular/cdk/collections';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TreeTableComponent implements OnInit {

  expandedElement: any | null = null;

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;

  initialSelection: any = [];
  allowMultiSelect: boolean = false;
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort) sort!: MatSort;
  public tableData = new MatTableDataSource<any>([]);

  @Input() ActionsBtns: Action[] = [];

  @Input() set dataSource(data: any[]) {
    this.setTableDataSource(data);
  }
  get dataSource(): any[] {
    return this.tableData.data;
  }
  @Input() hideCancelBox: boolean = false;
  @Input() numOfPages: number = 0;
  @Input() currentPageIndex: number = 0;
  @Input() limit: number = 5;
  @Input() isMobile: boolean = false;
  @Input() checkbox: boolean = false;
  @Input() showIcone: boolean = false;
  @Input() isTablet: boolean = false;
  @Input() isLaptop: boolean = false;
  @Input() isScreen: boolean = false;
  @Input() activeGate: boolean = false;
  @Input() cancelSelectionTrigger: boolean = false;
  @Input() columnsToDisplay: string[] = [];
  @Input() columnTitles: string[] = [];
  @Input() columnsNotToDisplsy: string[] = [];
  @Output() changeCategory = new EventEmitter<any>();
  @Output() rowSelected = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any[]>();
  @Output() cancelSelection = new EventEmitter<any>();
  @Output() getNextPage = new EventEmitter<any>();
  @Output() changeSize = new EventEmitter<any>();
  @Output() getPreviousPage = new EventEmitter<any>();
  @Output() getToPage = new EventEmitter<any>();
  @Output() clickPlate = new EventEmitter<any>();
  selectedSize: number = 5;
  selectedRows: any[] = [];
  @Input() link: { column: string, routeLink: string, param?: '' }[] = [];
  @Input() numberofCoulmn = 8
  isSelectedAll: boolean = false;
  columnsToDisplayCopy: string[] = [];
  columnsToDisplayInExpand: string[] = [];
  columnTitlesCopy: string[] = [];
  coumnsToDisplayCopyWithSelect: string[] = [];
  showOnlyIcon = false;
  numbers: Array<number> = Array<number>();
  numberOfPagesShown: number = 5;// show first and last pages
  sizes: Sizes[] = [{ size: 5, label: "" }, { size: 10, label: "" },
  { size: 50, label: "" }, { size: 100, label: "" }];

  color: ThemePalette = 'primary';

  constructor(
  ) {
    if (window.innerWidth < DEVICE_INNER_WINDOW_SIZE.LABTOP) {
      this.showOnlyIcon = true;
    } else {
      this.showOnlyIcon = false;
    }
  }

  ngOnInit(): void {
    this.sizes.forEach(element => {
      element.label = "" + element.size;
    });
  }
   selectRows(event: any, row: any) {
    if (event.checked) {
      this.selectedRows.push(row);
    } else {
      const index = this.selectedRows.findIndex(r => r === row);
      if (index >= 0) {
        this.selectedRows.splice(index, 1);
      }
    }

    this.emitSelection();
  }

  selectAll(event: any) {
    if (event.checked) {
      this.isSelectedAll = event.checked;
      this.dataSource.forEach(row => row.isChecked = this.isSelectedAll);
      this.selectedRows = this.isSelectedAll ? [...this.dataSource] : [];
    } else {
      this.selectedRows = [];
      this.clearSelection();
    }
    console.log(this.selectedRows)

    this.emitSelection();
  }
  private emitSelection() {
    this.selectionChange.emit(this.selectedRows);
  }
  clearSelection() {
    this.isSelectedAll = false;
    this.selectedRows = [];
    this.dataSource.forEach(row => row.isChecked = false);
    this.emitSelection();
    this.TriggerToFreeSelect();
    this.cancelSelection.emit({checkbox:false})

  }
  @Output() view = new EventEmitter<any>();//
  @Output() edit = new EventEmitter<any>();//
  @Output() edit_park = new EventEmitter<any>();//
  @Output() delete = new EventEmitter<any>();//
  @Output() assign = new EventEmitter<any>();//
  TriggerAction(action: Action, row: any) {
    this.selectRow(row);
    switch (action.key) {
      case ACTIONS.VIEW:
        this.view.emit();
        break;
      case ACTIONS.EDIT:
        this.edit.emit();
        break;
      case ACTIONS.DELETE:
        this.delete.emit(row);
        break;
      case ACTIONS.ASSING_ROLE:
        this.assign.emit(row);
        break;
      case ACTIONS.EDIT_PARK:
        this.edit_park.emit(row);
        break;
    }
  }
  TriggerSelect(row: any) {
    console.log(row)
    this.selection.toggle(row);
    this.rowSelected.emit(this.selection.selected);
  }
  TriggerToFreeSelect() {
    this.selection.toggle(null);
    this.rowSelected.emit([]);
  }

  selectCloseTicket() {
    if (this.dataSource.length > 0) {
      if (!this.checkbox)
        this.checkbox = true;
    }
  }

  setTableDataSource(data: any[]) {
    this.tableData = new MatTableDataSource<any>(data);
  }
  ngOnChanges(): void {
    this.columnsToDisplayCopy = this.columnsToDisplay?.slice(0);
    this.columnsToDisplayInExpand = [];
    this.columnTitlesCopy = this.columnTitles.slice(0);
    this.coumnsToDisplayCopyWithSelect = this.columnsToDisplay?.slice(0);
    if (this.columnTitles.length > this.numberofCoulmn)
      this.coumnsToDisplayCopyWithSelect.push('more');
    if (this.checkbox)
      this.coumnsToDisplayCopyWithSelect.push('select');

    this.handleTable();
    if (this.limit > 0) {
      this.selectedSize = this.limit
    }
    this.numbers = Array<number>();
    for (let index = 0; index < this.numOfPages; index++) {
      this.numbers.push(index);

    }

    if(this.cancelSelectionTrigger)
      this.clearSelection()
  }

  ngAfterViewInit() {
    this.tableData.sort = this.sort;
  }

  public innerWidth: number = 0;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.handleTable();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableData.filter = filterValue.trim().toLowerCase();

  }
  clickOncolumn(columnValue, column: string) {

    if (column.toLowerCase() === 'plate') {
      this.clickPlate.emit({ value: columnValue });
    }
  }
  handleTable() {
    this.innerWidth = window.innerWidth;


    if (

      this.innerWidth >= DEVICE_INNER_WINDOW_SIZE.LABTOP ||
      this.innerWidth >= DEVICE_INNER_WINDOW_SIZE.SCREEN

    ) {
      this.columnsToDisplayCopy = this.columnsToDisplay?.slice(0, this.numberofCoulmn);
      this.columnsToDisplayInExpand = this.columnsToDisplay?.slice(this.numberofCoulmn);
      this.columnTitlesCopy = this.columnTitles.slice(0, this.numberofCoulmn);
      this.coumnsToDisplayCopyWithSelect = this.columnsToDisplay?.slice(0, this.numberofCoulmn);
      if (this.columnTitles.length > this.numberofCoulmn)
        this.coumnsToDisplayCopyWithSelect.push('more')
      if (this.checkbox)
        this.coumnsToDisplayCopyWithSelect.push('select');
    } else if (this.innerWidth >= DEVICE_INNER_WINDOW_SIZE.TABLET && this.innerWidth < DEVICE_INNER_WINDOW_SIZE.LABTOP) {
      this.columnsToDisplayCopy = this.columnsToDisplay?.slice(0, 6);
      this.columnsToDisplayInExpand = this.columnsToDisplay?.slice(6);
      this.columnTitlesCopy = this.columnTitles.slice(0, 6);
      this.coumnsToDisplayCopyWithSelect = this.columnsToDisplay?.slice(0, 6);
      if (this.columnTitles.length > 6)
        this.coumnsToDisplayCopyWithSelect.push('more')
      if (this.checkbox)
        this.coumnsToDisplayCopyWithSelect.push('select');
    } else if (
      this.innerWidth < DEVICE_INNER_WINDOW_SIZE.TABLET
    ) {
      if (this.ActionsBtns.length == 0) {
        this.columnsToDisplayCopy = this.columnsToDisplay?.slice(0, 3);
        this.columnsToDisplayInExpand = this.columnsToDisplay?.slice(3);
        this.columnTitlesCopy = this.columnTitles.slice(0, 3);
        this.coumnsToDisplayCopyWithSelect = this.columnsToDisplay?.slice(0, 3);
      }
      else {
        this.columnsToDisplayCopy = this.columnsToDisplay?.slice(0, 2);
        this.columnsToDisplayInExpand = this.columnsToDisplay?.slice(3);
        this.columnTitlesCopy = this.columnTitles.slice(0, 2);
        this.coumnsToDisplayCopyWithSelect = this.columnsToDisplay?.slice(0, 2);
      }
      if (this.columnTitles.length > 3)
        this.coumnsToDisplayCopyWithSelect.push('more')
      if (this.checkbox)
        this.coumnsToDisplayCopyWithSelect.push('select');
    } else {
      this.columnsToDisplayCopy = this.columnsToDisplay?.slice(0);
      this.columnsToDisplayInExpand = [];
      this.columnTitlesCopy = this.columnTitles.slice(0);
      this.coumnsToDisplayCopyWithSelect = this.columnsToDisplay?.slice(0);
      this.coumnsToDisplayCopyWithSelect.push('more')
      if (this.checkbox)
        this.coumnsToDisplayCopyWithSelect.push('select');
    }
    if (this.ActionsBtns.length > 0) {
      this.columnsToDisplayCopy.push("Actions")
      this.columnTitlesCopy.push("Actions");
      this.coumnsToDisplayCopyWithSelect.push("Actions");
    }
    if (this.checkbox) {
      this.columnsToDisplayCopy.push("Select")
      this.columnTitlesCopy.push("Select");
      this.coumnsToDisplayCopyWithSelect.push("Select");
    }

  }
  checkColumnIfLink(column) {
    return this.link.findIndex(e => e.column === column) > -1 ? true : false
  }
  getTheLink(column,columnValue?): string {
      this.link.forEach((ele,i) => {
        if (ele.column.toLowerCase() === column.toLowerCase())
          this.link[i].param = columnValue
      })
    return this.link.find(e => e.column === column)?.routeLink
  }
  getTheParam(column) {
    return { plate: this.link.find(e => e.column === column)?.param }
  }
  pageSelected = 0;
  SelectPage(page: number) {
    this.pageSelected = page;
    this.getToPage.emit(page);
  }
  selectRow(row: any) {
    this.selection.toggle(row);
    this.rowSelected.emit(row);
  }

  clickRow(row: any) {
    this.changeCategory.emit(row);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableData.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.tableData.data.forEach(row => this.selection.select(row));
  }


  isRowDisplayed(key: any): boolean {
    let index = this.columnsNotToDisplsy.findIndex((element: any) => element == key);
    if (index !== -1) {
      return false;
    }

    let found = this.columnsToDisplayCopy.findIndex(element => element == key);
    if (found === -1) {
      return true;
    }
    return false;
  }


  getIndex(key: any) {
    return this.columnsToDisplayCopy.length + this.columnsToDisplayInExpand.findIndex(element => element == key);
  }

  getPrePage(): void {
    this.pageSelected = this.pageSelected - 1;
    this.getPreviousPage.emit();
  }

  getNxtPage(): void {
    this.pageSelected = this.pageSelected + 1;
    this.getNextPage.emit();
  }


  changePageSize(event: any) {
    this.changeSize.emit(event?.value);
  }

  sortData(sort: Sort) {
    const data = this.dataSource.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      return compare(a[sort.active], b[sort.active], isAsc);
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


export interface Sizes {
  size: number;
  label: string;
}
export interface Action {
  key: string;
  text: string;
  icon: string;
}
